import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import moment from "moment";
import { useParams } from "react-router-dom";
import { title } from "../config";
import { getLogById } from "../services/logApi";

export function DeployLog() {
  const [items, setItems] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    reloadSite(id);
  }, [id]);
  const reloadSite = async (id) => {
    if (id) {
      setItems(await getLogById(id));
    } else {
      setItems([]);
    }
  };

  return (
    <div>
      <h3>{title} | Logs</h3>
      <AppMenu title="States" />
      <LogList items={items} />
    </div>
  );
}

const LogList = ({ items }) => {
  return (
    
    <Table>
      <thead>
        <tr>
        <th>User Name</th>
          <th >Domain</th>
          <th>Deploy Time</th>
         
        </tr>
      </thead>
      <tbody>
      {items && items.length > 0 ? (
          items.map((item) => (
            <tr key={item._id}>
              <td>{item.userName}</td>
              <td>
                <a href={`https://${item.domain}`}>{item.domain}</a>
              </td>
              <td>
                {moment(item.deployAt)
                  .format("hh:mm A - DD/MM/YYYY")}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">Nothing deployed till now</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
