import React, { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { addSiteData, getSiteById, updateSiteData } from "../services/siteAPI";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import Select from "react-select";
import { siteActiveInactive } from "../services/constant";
import { getUserDetails } from "../services/userStorage";
import moment from "moment";
import { Table } from "reactstrap";

export default function AddSite(props) {
  const navigate = useNavigate();
  const { recordId } = useParams();
  const [active, setActive] = useState([]);
  const userDetails = getUserDetails();

  const [site, setSite] = useState({
    name: "",
    link: "",
    version: "",
    databases: "",
    active: "",
    domain: "",
    email: "",
    comment: "",
    exDate: "",
    depDate: "",
    notes: [],
  });

  const [buttonLabel, setBLabel] = useState("Add");
  const loadSite = async (id) => {
    let record = await getSiteById(id);
    setSite(record);
    setBLabel("Update");
  };
  const loadData = async () => {
    let status = siteActiveInactive;
    setActive(status);
  };

  useEffect(() => {
    if (recordId) {
      loadSite(recordId);
    }
    loadData();
  }, []);
  const handleChange = (e) => {
    setSite({ ...site, [e.target.name]: e.target.value });
  };
  const handleActiveChange = (e) => {
    setSite({ ...site, active: e.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!site.name.length) {
      return;
    }
    const newItem = { ...site, notetime: new Date() };
    newItem.useremail = userDetails.email;
    if (site._id !== undefined) {
      try {
        const updatedData = await updateSiteData(newItem);
        setSite(updatedData);
        navigate("/site");
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await addSiteData(newItem);
        navigate("/site");
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <div>
      <h3>
        {title} ({buttonLabel} Site)
      </h3>
      <AppMenu title="Add Site" />
      <div className="p-3">
        <div className="card p-5">
          <form className="container float-center needs-validation">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-3">
                <label className="form-label">Name</label>
                <input
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={site.name}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Link</label>
                <input
                  placeholder="Link"
                  name="link"
                  onChange={handleChange}
                  value={site.link}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Active</label>
                <Select
                  name="active"
                  className="basic-single mb-0"
                  options={active}
                  getOptionValue={(active) => active.value}
                  getOptionLabel={(active) => active.name}
                  onChange={handleActiveChange}
                  autoFocus={true}
                  value={active.find((i) => i.value == site.active)}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Domain</label>
                <input
                  placeholder="Domain"
                  name="domain"
                  onChange={handleChange}
                  value={site.domain}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Comment</label>
                <input
                  placeholder="Comment"
                  name="comment"
                  onChange={handleChange}
                  value={site.comment}
                  className="form-control"
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Contact</label>
                <input
                  placeholder="contact"
                  name="contact"
                  onChange={handleChange}
                  value={site.contact}
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="col-md-4 col-sm-6 mb-3">
            <input
            type="button"
            onClick={handleSubmit}
            value={buttonLabel}
            className="btn  btn-primary btn-block"
          />
          &nbsp;&nbsp; &nbsp;&nbsp;
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const NoteList = ({ items }) => {
  return (

    <Table className="table table-hover" hover responsive>
      <thead>
        <tr>
          <th>Time</th>
          <th>Username</th>
          <th>Freshdesk Link</th>
          <th>Action</th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item) => (
            <tr key={item.id}>
              <td>
                {item.time &&
                  moment(item.time)
                    .zone("Asia/Kolkata")
                    .format("hh:mm A - DD/MM/YYYY")}
              </td>
              <td>{item.userName} </td>
              <td>{item.freshdeskLink} </td>
              <td>{item.action} </td>
              <td>{item.note} </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const AddNotes = ({ site, userDetails }) => {
  const [noteValues, setNoteValues] = useState({
    note: "",
    freshdeskLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNoteValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const AddNotes = () => {
    const newNote = {
      time: new Date(),
      id: Date.now(),
      userName: userDetails.name,
      userId: userDetails._id,
      note: noteValues.note,
      action: noteValues.action,
      freshdeskLink: noteValues.freshdeskLink,
    };
    site.notes = site.notes || [];
    site.notes.unshift(newNote);
    updateSiteData(site);
    window.location.reload();
  };
  return (
    <form className="container float-center needs-validation">
      <div className="row mt-4">
        <div className="col-md-6 col-sm-12 mb-3">
          <label className="form-label">Note</label>
          <textarea
            placeholder="note"
            type="textarea"
            name="note"
            onChange={handleChange}
            value={noteValues.note}
            className="form-control"
            required
          />{" "}
        </div>
        <div className="col-md-3 col-sm-6 mb-3">
          <label className="form-label">Freshdesk Link</label>
          <input
            placeholder="freshdeskLink"
            name="freshdeskLink"
            onChange={handleChange}
            value={noteValues.freshdeskLink}
            className="form-control"
            required
          />
        </div>
        <div className="col-md-3 col-sm-6 mb-3">
          <label className="form-label">Action</label>
          <input
            placeholder="Action"
            name="action"
            onChange={handleChange}
            value={noteValues.action}
            className="form-control"
            required
          />
        </div>
      </div>
      <input
        type="button"
        value="Add Note"
        className="btn btn-block btn-secondary"
        onClick={() => {
          AddNotes();
        }}
      />
    </form>
  );
};
