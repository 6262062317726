const localUser = "user";

export const getUserDetails = () => {
    let userData = {}
    try{
        userData = JSON.parse(localStorage.getItem(localUser));
    }catch(e){
        
    }
    finally{
        return userData;
    }
}

export const clearLocalStorage =()=>{
    localStorage.clear();
}