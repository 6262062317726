import axios from "axios";
import {getAPIUrl} from '../config'
import { headerConfig } from "./authConfig";

const serviceEndPoint = `${getAPIUrl()}/api/state`;

export const getStateData = async () => {
  const result = await axios.get(serviceEndPoint,headerConfig());
  return result.data;
};
export const getStatesById = async (id)  => {
  const result = await axios.get(`${serviceEndPoint}/${id}`,headerConfig());
  return result.data;
}

