import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { getStatesById } from "../services/stateAPI";
import { getSiteData } from "../services/siteAPI";
import moment from "moment";
import { useParams } from "react-router-dom";
import { title } from "../config";

 const SiteLog = () => {
  const [items, setItems] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    reloadSite(id);
  }, [id]);
  const reloadSite = async (id) => {
    if (id !== undefined) {
      setItems(await getStatesById(id));
    } else {
      setItems(await getSiteData());
    }
  };

  return (
    <div>
      <h3>{title} | States</h3>
      <AppMenu title="States" />
      <StateList items={items} />
    </div>
  );
}

const StateList = ({ items }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Time </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item._id}>
            <td>{item.name} </td>
            <td>{item.status} </td>
            <td>
              {
                moment(item.time)
                  .zone("Asia/Kolkata")
                  .format("hh:mm A - DD/MM/YYYY")
                // { moment.utc(item.time, 'hh:mm A').zone('Asia/Kolkata').format("hh:mm A- DD/MM/YYYY") }
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SiteLog;