export const siteActiveInactive = [
  { value: 0, name: "Inactive" },
  { value: 1, name: "Active" },
  { value: 2, name: "Active Old " }
];

export const userActiveInactive = [
  { value: 0, name: "Inactive" },
  { value: 1, name: "Active" },
];

export const userRoles = [
  { value: 1, name: "Super Admin" },
  { value: 2, name: "Admin" },
  { value: 3, name: "Developer" },
  { value: 4, name: "View User" },
];
