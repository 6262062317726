import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { getStatesById, getStateData } from "../services/stateAPI";
import moment from "moment";
import { useParams } from "react-router-dom";
import { title } from "../config";

export function State() {
  const [items, setItems] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    reloadSite(id);
  }, [id]);
  const reloadSite = async (id) => {
    if (id) {
      setItems(await getStatesById(id));
    } else {
      setItems(await getStateData());
    }
  };

  return (
    <div>
      <h3>{title} | States</h3>
      <AppMenu title="States" />
      <StateList items={items} />
    </div>
  );
}

const StateList = ({ items }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Time </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item._id}>
            <td>{item.name} </td>
            <td>{item.cms} </td>
            <td>
              {moment(item.time)
                .zone("Asia/Kolkata")
                .format("hh:mm A - DD/MM/YYYY")}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
