import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import { getSiteData } from "../services/siteAPI";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Home = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [activeOldCount, setActiveOldCount] = useState(0);
  const [inActive, setInActive] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [cmsErrorCount, setCMSErrorCount] = useState(0);

  const [versionObj, setVersionObj] = useState(0);
  const [expDateCount, setExpDateCount] = useState(0);
  const [expTowDays, setExpTowDays] = useState(0);
  const [emailEmpty, setEmailEmpty] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [memoryLow, setMemoryLow] = useState(0);
  const [execFork, setExecFork] = useState(0);
  let versionCount = {};
  const addVersion = (version) => {
    if (version == "") {
      version = "NA";
    }
    if (versionCount[version]) {
      versionCount[version] = versionCount[version] + 1;
    } else {
      versionCount[version] = 1;
    }
  };
  const getFreeSpace = (free, size) => {
    const percentageFull = ((size - free) / size) * 100;
    const roundValue = Math.round(percentageFull);
    return roundValue;
  };

  useEffect(() => {
    reloadSite();
  }, []);
  const reloadSite = async () => {
    let temp = await getSiteData();
    let activeCount = 0;
    let inActiveCount = 0;
    let activeOldCount = 0;
    let errorCount = 0;
    let cmsErrorCount = 0;
    let emailNullCount = 0;
    let emailCount = 0;
    let expDateCount = 0;
    let expTowDays = 0;
    let memoryLowCount = 0;
    let execForkCount = 0;
    temp.map(
      ({
        active,
        cms,
        status,
        version,
        exDate,
        email,
        free,
        size,
        exec_mode,
      }) => {
        if (active == "1" || active == "2") {
          if (cms == "error") {
            cmsErrorCount++;
          }
        }
        if (active == 0) {
          inActiveCount++;
        }
        if (active == 2) {
          activeOldCount++;
        }
        if (active == 1) {
          activeCount++;
          addVersion(version);
          if (status == "error") {
            errorCount++;
          }
          if (moment(exDate) <= moment(new Date()).add(7, "d")) {
            expDateCount++;
          }
          if (moment(exDate) <= moment(new Date()).add(2, "d")) {
            expTowDays++;
          }
          if (getFreeSpace(free, size) > 90) {
            memoryLowCount++;
          }
        }
        if (exec_mode == "fork_mode") {
          execForkCount++;
        }
        if (email == "") {
          emailNullCount++;
        }
        if (email !== "") {
          emailCount++;
        }
      }
    );
    setVersionObj(versionCount);
    setActiveCount(activeCount);
    setInActive(inActiveCount);
    setErrorCount(errorCount);
    setExpDateCount(expDateCount);
    setExpTowDays(expTowDays);
    setEmailEmpty(emailNullCount);
    setEmailCount(emailCount);
    setCMSErrorCount(cmsErrorCount);
    setTotal(temp.length);
    setActiveOldCount(activeOldCount);
    setMemoryLow(memoryLowCount);
    setExecFork(execForkCount);
  };
  return (
    <div>
      <h3>{title} | Home</h3>
      <AppMenu />
      <div className="row m-lg-1">
        <div className="col-md-4 col-sm-12">
          <Alert color="primary" className="p-3">
            Total
            <a className="alert-link" href="/site" rel="noreferrer">
              {" "}
              {total}
            </a>{" "}
            Sites.
          </Alert>
        </div>
        <div className="col-md-4 col-sm-12">
          <Alert color="danger" className="p-3">
            CMS Error
            <a className="alert-link" href="/sites/cms/error" rel="noreferrer">
              {" "}
              {cmsErrorCount}{" "}
            </a>
            Sites.
          </Alert>
        </div>
      </div>
    </div>
  );
};
export default Home;
