import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/user`;

export const getUserData = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  return result.data;
};
export const addUserData = async (data) => {
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};
export const getUserById = async (siteId) => {
  const result = await axios.get(
    `${serviceEndPoint}/${siteId}`,
    headerConfig()
  );
  return result.data;
};
export const updateUserData = async (data) => {
  const result = await axios.put(
    serviceEndPoint,
    {
      id: data._id,
      name: data.name,
      password: data.password,
      email: data.email,
      phone: data.phone,
      active: data.active,
      role: data.role,
      accessibleSites: data.accessibleSites
    },
    headerConfig()
  );
  return result.data;
};
export const deleteUser = async (id) => {
  const configData = headerConfig();
  const result = await axios.delete(
    serviceEndPoint,
    {
      data: {
        id: id,
      },
      headers:configData.headers
    }
  );
  return result.data;
};
