import React from "react";
import { Link } from "react-router-dom";
import { userRoles } from "../services/constant";
import { clearLocalStorage, getUserDetails } from "../services/userStorage";
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import UserContext from "../components/UserContext";

function AppMenu() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const getRole = (role) => {
    let roleData = userRoles.find((item) => item.value === role);
    if (roleData) {
      return roleData.name;
    } else {
      return "";
    }
  };

  const userDetails = getUserDetails();
  return (
    <div>
       {userDetails && userDetails.role <= 2 ? <><Link to={"/home"} className="text-decoration-none text-dark">Home</Link> &nbsp; | &nbsp;</> : ""}
      <Link to={"/site"} className="text-decoration-none text-dark">Sites</Link>&nbsp; | &nbsp;
      {userDetails && userDetails.role == 1 ? <> <Link to="/user" className="text-decoration-none  text-dark">Users</Link> &nbsp; | &nbsp; </> : ""}
      <Link
        onClick={() => {
          clearLocalStorage();
          userContext.doLogin();
          window.location = "/login";
        }}
        className="text-decoration-none text-dark"
      > Logout
      </Link>
      {userDetails ?
        <span className="me-3 float-end h5">
          {userDetails && userDetails.name}  {" "}
          ({userDetails && getRole(userDetails.role)})
        </span>
        : ""}
      <hr />
    </div>
  );
}
export default AppMenu;
