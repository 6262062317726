import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/site`;
const commandServiceEndPoint = `${getAPIUrl()}/api/command`;
let siteCache = null;
export const getSiteData = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  return result.data;
};

export const addSiteData = async (data) => {
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};

export const getSiteById = async (siteId) => {
  const result = await axios.get(`${serviceEndPoint}/${siteId}`, headerConfig());
  return result.data;
};

export const getGitBranchById = async (siteId,gitbranch) => {
  const result = await axios.get(`${commandServiceEndPoint}/${siteId}/${gitbranch}`, headerConfig());
  return result.data;
};

export const getGitStatusById = async (siteId,gitstatus) => {
  const result = await axios.get(`${commandServiceEndPoint}/${siteId}/${gitstatus}`, headerConfig());
  return result.data;
};

export const getGitLogById = async (siteId,gitlog) => {
  const result = await axios.get(`${commandServiceEndPoint}/${siteId}/${gitlog}`, headerConfig());
  return result.data;
};

export const getSpaceById = async (siteId,space) => {
  const result = await axios.get(`${commandServiceEndPoint}/${siteId}/${space}`, headerConfig());
  return result.data;
};

export const getDeploySiteById = async (siteId,deploy) => {
  const result = await axios.get(`${commandServiceEndPoint}/${siteId}/${deploy}`, headerConfig());
  return result.data;
};

export const getSiteByUserId = async (id) => {
  const configData = headerConfig();
  try {
    const result = await axios.get(`${serviceEndPoint}/${id}/usersite`,
    {...configData},
    );
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateSiteData = async (data) => {
  const configData = headerConfig();

  const result = await axios.put(
    serviceEndPoint,
    {
      _id: data._id,
      id: data._id,
      name: data.name,
      exDate: data.exDate,
      link: data.link,
      version: data.version,
      databases: data.databases,
      email: data.useremail,
      active: data.active,
      domain: data.domain,
      comment: data.comment,
      contact: data.contact,
      notes: data.notes,
      depDate: data.depDate,
      userName: data.username
    },
    configData
  );
  return result.data;
};

export const deleteSite = async (id) => {
  const configData = headerConfig();
  const result = await axios.delete(
    serviceEndPoint,
    {
      data: {
        id: id,
      },
      headers: configData.headers
    }
  );
  return result.data;
};
