import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Home from './containers/Home';
import PageNotFound from './containers/PageNotFound';
import UserCtx from './components/UserContext';
import { Site } from './containers/Site'
import SiteLog from './containers/SiteLogs'
import { State } from './containers/State'
import AddSite from './containers/AddSite';
import Adduser from './containers/AddUser';
import { User } from './containers/User';
import Login from './containers/Login';
import Protected from './components/Protected';
import { DeployLog } from './containers/DeployLog';

const loginStatus = () => {
  if (localStorage.getItem('isLoggedIn') === 'true') {
    return true;
  } else {
    return false;
  }
}
const checkRole = (userRole, allowedRoles) => {
  return allowedRoles.includes(userRole);
}

const PrivateRoute = ({ element, allowedRoles, isLoggedIn }) => {
  if (isLoggedIn && checkRole(JSON.parse(localStorage.getItem('user')).role, allowedRoles)) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(loginStatus());
  return (
    <Router>
      <UserCtx.Provider
        value={{
          isLoggedIn,
          doLogin: (result) => {
            if (result) {
              localStorage.setItem("access_token", result.token);
              localStorage.setItem("user", JSON.stringify(result.user));
              localStorage.setItem('isLoggedIn', "true");
              setIsLoggedIn(true);
            } else {
              localStorage.removeItem('isLoggedIn');
              localStorage.removeItem("access_token");
              localStorage.removeItem("user");
              setIsLoggedIn(false)
            }
          }
        }}
      > <div style={{ marginLeft: '10px', marginTop: '0px' }}>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<Navigate to={{ pathname: "/home" }} />} />
            <Route exact path="/home" element={
               <PrivateRoute
               element={<Protected isLoggedIn={isLoggedIn}><Home /></Protected>}
               allowedRoles={[1, 2]}
               isLoggedIn={isLoggedIn}
             />
            } />
            <Route exact path="/site" element={
              <Protected isLoggedIn={isLoggedIn}><Site /></Protected>
            } />
            <Route exact path="/site/add" element={
             <PrivateRoute
             element={<Protected isLoggedIn={isLoggedIn}><AddSite /></Protected>}
             allowedRoles={[1, 2]}
             isLoggedIn={isLoggedIn}
           />
            } />
            <Route exact path="/site/edit/:recordId" element={
              <PrivateRoute
              element={<Protected isLoggedIn={isLoggedIn}><AddSite /></Protected>}
              allowedRoles={[1, 2]}
              isLoggedIn={isLoggedIn}
            />
            } />
             <Route exact path="/site/log/:id" element={
              <Protected isLoggedIn={isLoggedIn}><DeployLog /></Protected>
            } />
            <Route exact path="/sites/:field/:value" element={
              <Protected isLoggedIn={isLoggedIn}><Site /></Protected>
            } />
            <Route exact path="/details" element={
              <Protected isLoggedIn={isLoggedIn}><SiteLog /></Protected>
            } />
            <Route exact path="/state/:id" element={ <PrivateRoute
              element={<Protected isLoggedIn={isLoggedIn}><State /></Protected>}
              allowedRoles={[1, 2]}
              isLoggedIn={isLoggedIn}
            />} />
            <Route exact path="/user" element={
             <PrivateRoute
             element={<Protected isLoggedIn={isLoggedIn}><User /></Protected>}
             allowedRoles={[1]}
             isLoggedIn={isLoggedIn}
           />
            } />
            <Route exact path="/user/add" element={
              <PrivateRoute
              element={<Protected isLoggedIn={isLoggedIn}><Adduser /></Protected>}
              allowedRoles={[1]}
              isLoggedIn={isLoggedIn}
            />
            } />
            <Route exact path="/user/edit/:recordId" element={
               <PrivateRoute
               element={<Protected isLoggedIn={isLoggedIn}><Adduser /></Protected>}
               allowedRoles={[1]}
               isLoggedIn={isLoggedIn}
             />
            } />
          </Routes>
        </div>
      </UserCtx.Provider>
    </Router>
  );
}
export default App;