import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/logs`;

export const addLogData = async (data) => {
    const result = await axios.post(serviceEndPoint, data, headerConfig());
    return result.data;
  };

export const getLogById = async (id)  => {
    const result = await axios.get(`${serviceEndPoint}/${id}`,headerConfig());
    return result.data;
  }