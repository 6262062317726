import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser, getUserData } from "../services/userAPI";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { title } from "../config";
import {userActiveInactive, userRoles} from "../services/constant";


export function User() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    reloadUser();
  }, []);

  const reloadUser = async () => {
    let records = await getUserData();
    setItems(records);
  };
  const doDelete = async (data) => {
    await deleteUser(data);
    reloadUser();
  };
  const doEdit = (id) => {
    navigate(`/user/edit/${id}`);
  };
  return (
    <div>
      <h3>{title} | Users</h3>
      <AppMenu title="Users" />
      <span className="headerMargin">{getAddButton(navigate)}</span>
      <UserList items={items} doDelete={doDelete} doEdit={doEdit} />
    </div>
  );
}

const getStatus = (status) => {
  let statusData = userActiveInactive.find((item) => item.value == status);
  if (statusData) {
    return statusData.name;
  } else {
    return "";
  }
};
const getRole = (role) => {
  let roleData = userRoles.find((item) => item.value == role);
  if (roleData) {
    return roleData.name;
  } else {
    return "";
  }
};
const getAddButton = (navigate) => {
  return (
    <button
      className="fa-sharp fa-plus btn btn-primary"
      onClick={() => {
        navigate("/user/add");
      }}
      variant="contained"
    >
      Add
    </button>
  );
};

function UserList({ items, doDelete, doEdit }) {
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Active</th>
          <th>Last Login</th>
          <th>Role </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.length > 0 &&
          items.map((item) => (
            <tr key={item._id}>
              <td></td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
              <td>{getStatus(item.active)}</td>
              <td>{item.lastLogin}</td>
              <td>{getRole(item.role)}</td>
              <td className="d-flex">
                <i
                  className="fas fa-edit btn btn-primary m-2"
                  onClick={() => doEdit(item._id)}
                  aria-hidden="true"
                />
                <i
                  className="fa-solid fa-trash-can btn btn-danger m-2"
                  onClick={() => doDelete(item._id)}
                  aria-hidden="true"
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
